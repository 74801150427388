import React from "react";
import {
  mapping,
  FormatDate,
  ButtonSolid,
  ViewTask,
  TaskForm,
  Loading,
  taskManagementStyle,
} from "@aim-mf/styleguide";
import { TaskPanel } from "./components/taskPanel";

class TaskManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewTask: { display: false },
      viewHistory: false,
      createTask: false,
      // inboxSearchTerm: "",
      inboxSearchResults: [],
      alertInboxSearchResults: [],
      inboxFilter1: "Filter by",
      inboxFilter2: "Sort by",
      // pendingSearchTerm: "",
      pendingSearchResults: [],
      alertPendingSearchResults: [],
      pendingFilter1: "Filter by",
      pendingFilter2: "Sort by",
      historySearchResults: [],
      alertHistorySearchResults: [],
      historyFilter1: "Filter by",
      historyFilter2: "Sort by",
    };
  }
  componentWillReceiveProps(nextProps, nextContext) {
    //console.log(nextProps.taskList);
    if (
      JSON.stringify(nextProps.taskList) !==
        JSON.stringify(this.props.taskList) ||
      JSON.stringify(nextProps.alertList) !==
        JSON.stringify(this.props.alertList)
    ) {
      //console.log(nextProps.alertList)
      this.backendDataToFrontEnd(
        nextProps.taskList,
        nextProps.currentUser.username
      );
      this.alertBackendToFrontEnd(
        nextProps.alertList,
        nextProps.currentUser.username
      );
    }
  }

  mappingAlertBackendToFrontEndData = (alert, username) => {
    //console.log(alert);
    let frontendTaskData = {
      id: alert.id,
      category:
        alert.transferees.includes(username) || alert.transferees.length === 0
          ? "inbox"
          : "pending",
      type: "alert",
      title: alert.description,
      description: alert.description,
      date: FormatDate(alert.create_datetime, "DD/MM/YYYY"),
      dueDate: alert.due_date,
      status: alert.status,
      from: alert.creator,
      to: alert.owners[0],
      commentDraft: "",
      comments: [],
      actions: [],
      attachments: [],
      alert_id: alert.id,
      removeTaskAttachmentID: [],
    };
    return frontendTaskData;
  };

  mappingBackendToFrontEndData(task, username) {
    let frontendTaskData = {
      id: task.id,
      category: task.to.includes(username)
        ? "inbox"
        : task.task_from === username
        ? "pending"
        : "error",
      type: "task",
      title: task.title,
      description: task.description,
      date: FormatDate(task.create_datetime, "DD/MM/YYYY"),
      dueDate: task.due_date,
      status: task.status,
      from: task.task_from,
      creator: task.task_creator,
      to: task.to,
      commentDraft: "",
      comments: [],
      actions: [],
      attachments: task.attachments,
      alert_id: task.alert_id,
      removeTaskAttachmentID: [],
    };
    task.actions.forEach((action) => {
      frontendTaskData.actions.push({
        actionID: action.action_id,
        actionType: action.action_type,
        actionContent: [
          {
            type: action.action_subtype,
            question: action.action_question,
            answer: action.action_answer,
            options: action.action_options,
            selected: action.action_choices,
            attachments: action.attachments,
            removeActionAttachmentID: [],
          },
        ],
      });
    });
    task.task_comment.forEach((comment) => {
      frontendTaskData.comments.push({
        commentText: comment.content,
        dateTime: comment.time,
        userDisplay: this.props.userList[comment.username],
        userName: comment.username,
      });
    });
    return frontendTaskData;
  }

  render() {
    return (
      <div>
        <div
          style={
            this.state.viewTask.display ||
            this.state.createTask ||
            this.props.loading
              ? BlurEffectStyle
              : { display: "inline-flex", width: "100%" }
          }
        >
          <div style={taskManagementStyle.divWrapper}>
            <div>
              {/* PAGE TITLE ======================================================================= */}
              <div style={mapping["heading/h3/lightleft"]}>Task Management</div>
              {/* HEADER BUTTONS =================================================================== */}
              <div style={taskManagementStyle.buttonWrapper}>
                <ButtonSolid
                  name={"Create New Task"}
                  width={taskManagementStyle.buttonWidth}
                  height={taskManagementStyle.buttonHeight}
                  color={"Primary"}
                  clickEvent={() => {
                    this.setState({ createTask: true });
                  }}
                />
                <div style={{ width: "10px" }} />
                <ButtonSolid
                  name={"View History"}
                  width={taskManagementStyle.buttonWidth}
                  height={taskManagementStyle.buttonHeight}
                  color={"Info"}
                  clickEvent={() => {
                    this.setState({ viewHistory: true });
                  }}
                />
                <div style={{ width: "10px" }} />
                <ButtonSolid
                  name={"All"}
                  width={taskManagementStyle.smallButtonWidth}
                  height={taskManagementStyle.buttonHeight}
                  color={"Secondary"}
                  clickEvent={() => {
                    this.setState({ viewHistory: false });
                  }}
                />
              </div>
            </div>
            {/* PANELS ======================================================================= */}
            {this.state.viewHistory === false && (
              <div style={{ clear: "both" }}>
                <TaskPanel
                  userList={this.props.userList}
                  title={"Inbox"}
                  handleSearch={this.handleSearchInbox}
                  filterValue1={this.state.inboxFilter1}
                  filterList1={Filter1List}
                  handleFilter1={(event) => {
                    this.handleFilterIn1(event.value);
                  }}
                  filterValue2={this.state.inboxFilter2}
                  filterList2={Filter2List}
                  handleFilter2={(event) => {
                    this.handleFilterIn2(event.value);
                  }}
                  taskData={this.state.inboxSearchResults.concat(
                    this.state.alertInboxSearchResults
                  )}
                  taskDataShown={this.state.inboxSearchResults.concat(
                    this.state.alertInboxSearchResults
                  )}
                  handleViewTask={this.handleViewTask}
                  viewHistory={this.state.viewHistory}
                />
                <TaskPanel
                  userList={this.props.userList}
                  title={"Pending"}
                  handleSearch={this.handleSearchPending}
                  filterValue1={this.state.pendingFilter1}
                  filterList1={Filter1List}
                  handleFilter1={(event) => {
                    this.handleFilterPend1(event.value);
                  }}
                  filterValue2={this.state.pendingFilter2}
                  filterList2={Filter2List}
                  handleFilter2={(event) => {
                    this.handleFilterPend2(event.value);
                  }}
                  taskData={this.state.pendingSearchResults.concat(
                    this.state.alertPendingSearchResults
                  )}
                  taskDataShown={this.state.pendingSearchResults.concat(
                    this.state.alertPendingSearchResults
                  )}
                  handleViewTask={this.handleViewTask}
                  viewHistory={this.state.viewHistory}
                />
              </div>
            )}
            {this.state.viewHistory && (
              <div style={{ clear: "both" }}>
                <TaskPanel
                  userList={this.props.userList}
                  title={"History"}
                  handleSearch={this.handleSearchHistory}
                  filterValue1={this.state.historyFilter1}
                  filterList1={Filter1List}
                  handleFilter1={(event) => {
                    this.handleFilterHist1(event.value);
                  }}
                  filterValue2={this.state.historyFilter2}
                  filterList2={Filter2List}
                  handleFilter2={(event) => {
                    this.handleFilterHist2(event.value);
                  }}
                  taskData={this.state.historySearchResults.concat(
                    this.state.alertHistorySearchResults
                  )}
                  taskDataShown={this.state.historySearchResults.concat(
                    this.state.alertHistorySearchResults
                  )}
                  handleViewTask={this.handleViewTask}
                  viewHistory={this.state.viewHistory}
                />
              </div>
            )}
          </div>
        </div>
        {this.state.viewTask.display && (
          <ViewTask
            userList={this.props.userList}
            taskCardData={this.state.viewTask.Detail}
            uploadingFile={this.state.viewTask.Detail.uploadingFile}
            currentUser={this.props.currentUser}
            cancelFunction={this.handleViewTaskClose}
            handleViewTaskSave={this.handleViewTaskSave}
            handleTaskClose={this.handleTaskClose}
            handleTaskOpen={this.handleTaskOpen}
            viewHistory={this.state.viewHistory}
            handleTaskUserSwitch={this.props.handleTaskUserSwitch}
            handleAttachmentChange={this.handleAttachmentChange}
            handleRemoveUploadFile={this.handleRemoveUploadFile}
            handleTaskAttachmentDownload={
              this.props.handleTaskAttachmentDownload
            }
            handleTaskActionAttachmentDownload={
              this.props.handleTaskActionAttachmentDownload
            }
            handleActionAttachmentRemove={
              this.props.handleActionAttachmentRemove
            }
            disabled={
              this.props.currentUser.username ===
                this.state.viewTask.Detail.creator ||
              this.state.viewHistory ||
              this.state.viewTask.Detail.status === "closed"
            }
            taskAttachmentDisabled={
              this.props.currentUser.username !==
              this.state.viewTask.Detail.creator
            }
          />
        )}
        {this.state.createTask && (
          <TaskForm
            userList={this.props.userList}
            cancelFunction={() => {
              this.setState({ createTask: false });
            }}
            submitFunction={this.handleCreateTask}
            currentUser={this.props.currentUser.username}
            handleAttachmentChange={this.handleAttachmentChange}
            handleRemoveUploadFile={this.handleRemoveUploadFile}
          />
        )}
        {this.props.loading && (
          <Loading type={"bars"} color={mapping["Color_Basic_Primary"]} />
        )}
        <div style={{ height: "20px" }} />
      </div>
    );
  }

  handleRemoveUploadFile = (actionIndex, index) => {
    let uploadingFile = this.state.uploadingFile;
    uploadingFile.splice(index, 1);

    let alert = this.state.alert;
    alert.actions[index].attachment_file = uploadingFile;
    alert.actions[index].attachment_name = uploadingFile.map(
      (file) => file.name
    );
    alert.actions[index].attachment_content_type = uploadingFile.map(
      (file) => file.type
    );
    this.setState({ alert: alert });
  };
  handleCreateTask = (task) => {
    let taskPackage = { data: task };
    this.props.handleCreateTask(taskPackage);
    this.setState({ createTask: false });
  };

  handleViewTask = (Detail) => {
    this.setState({ viewTask: { display: true, Detail: Detail } });
  };
  handleViewTaskClose = () => {
    this.setState({ viewTask: { display: false, Detail: {} } });
  };
  handleViewTaskSave = (task) => {
    //console.log(this.mappingFrontendToBackend(task));
    this.props.handleTaskUpdate(task.id, this.mappingFrontendToBackend(task));
    this.setState({ viewTask: { display: false, Detail: {} } });
  };

  handleTaskClose = (taskID) => {
    this.setState({ viewTask: { display: false, Detail: {} } });
    this.props.handleTaskClose(taskID);
  };
  handleTaskOpen = (taskID) => {
    this.setState({ viewTask: { display: false, Detail: {} } });
    this.props.handleTaskOpen(taskID);
  };
  mappingFrontendToBackend = (frontendData) => {
    //console.log(frontendData);
    let comments = [];
    frontendData.comments.forEach((comment) => {
      //console.log(FormatDate(comment.dateTime, "YYYY-MM-DD HH:mm:ss"));
      comments.push({
        content: comment.commentText,
        username: comment.userName,
        time: FormatDate(comment.dateTime, "YYYY-MM-DD HH:mm:ss"),
      });
    });
    let actions = [];
    frontendData.actions.forEach((action) => {
      //console.log(action);
      actions.push({
        action_id: action.actionID,
        action_type: action.actionType,
        action_subtype: action.actionContent[0].type,
        action_question:
          action.actionContent[0].description ||
          action.actionContent[0].question,
        action_answer: action.actionContent[0].answer,
        action_options: action.actionContent[0].options,
        action_choices: action.actionContent[0].selected
          ? Array.isArray(action.actionContent[0].selected)
            ? action.actionContent[0].selected
            : [action.actionContent[0].selected]
          : [],
        action_new_attachment: action.newAttchments,
        action_remove_attachment_ID:
          action.actionContent[0].removeActionAttachmentID,
      });
    });
    let taskContent = {
      due_date: frontendData.dueDate || FormatDate(new Date(), "YYYY-MM-DD"),
      title: frontendData.title,
      description: frontendData.description,
      actions: actions,
      task_comment: comments,
      task_from: this.props.currentUser.username,
      task_new_attachments: frontendData.newAttchments,
      task_remove_attachment_ID: frontendData.removeTaskAttachmentID,
      //eventDeleteAction: this.state.eventDeleteAction
    };

    return taskContent;
  };

  handleSearchInbox = (value) => {
    let inboxSearchResults = this.state.inboxTask.filter((task) =>
      task.title.toLowerCase().includes(value.toLowerCase())
    );
    let alertInboxSearchResults = this.state.inboxAlert.filter((alert) =>
      alert.title.toLowerCase().includes(value.toLowerCase())
    );
    this.setState({
      inboxSearchResults: inboxSearchResults,
      alertInboxSearchResults: alertInboxSearchResults,
    });
  };
  handleSearchPending = (value) => {
    let pendingSearchResults = this.state.pendingTask.filter((task) =>
      task.title.toLowerCase().includes(value.toLowerCase())
    );
    let alertPendingSearchResults = this.state.pendingAlert.filter((alert) =>
      alert.title.toLowerCase().includes(value.toLowerCase())
    );
    this.setState({
      pendingSearchResults: pendingSearchResults,
      alertPendingSearchResults: alertPendingSearchResults,
    });
  };
  handleSearchHistory = (value) => {
    let historySearchResults = this.state.historyTask.filter((task) =>
      task.title.toLowerCase().includes(value.toLowerCase())
    );
    let alertHistorySearchResults = this.state.historyAlert.filter((alert) =>
      alert.title.toLowerCase().includes(value.toLowerCase())
    );
    this.setState({
      historySearchResults: historySearchResults,
      alertHistorySearchResults: alertHistorySearchResults,
    });
  };

  handleFilterIn1 = (value) => {
    this.setState({
      inboxFilter1: value,
    });
  };
  handleFilterIn2 = (value) => {
    this.setState({
      inboxFilter2: value,
    });
  };
  handleFilterPend1 = (value) => {
    this.setState({
      pendingFilter1: value,
    });
  };
  handleFilterPend2 = (value) => {
    this.setState({
      pendingFilter2: value,
    });
  };
  handleFilterHist1 = (value) => {
    this.setState({
      historyFilter1: value,
    });
  };
  handleFilterHist2 = (value) => {
    this.setState({
      historyFilter2: value,
    });
  };

  alertBackendToFrontEnd = (alertList, username) => {
    let alertdata = [];
    alertList.forEach((alert) => {
      alertdata.push(this.mappingAlertBackendToFrontEndData(alert, username));
    });
    let inboxAlert = alertdata.filter((alert) => {
      return alert.category === "inbox" && alert.status !== "closed";
    });
    let pendingAlert = alertdata.filter((alert) => {
      return alert.category === "pending" && alert.status !== "closed";
    });
    let historyAlert = alertdata.filter((alert) => {
      return alert.status === "closed"; //CHANGE THIS
    });

    this.setState({
      inboxAlert: inboxAlert,
      pendingAlert: pendingAlert,
      historyAlert: historyAlert,
      alertInboxSearchResults: inboxAlert,
      alertPendingSearchResults: pendingAlert,
      alertHistorySearchResults: historyAlert,
    });
  };

  backendDataToFrontEnd = (taskList, username) => {
    let taskData = [];
    taskList.forEach((task) => {
      taskData.push(this.mappingBackendToFrontEndData(task, username));
    });
    // console.log(taskData)
    let inboxTask = taskData.filter((task) => {
      return task.category === "inbox" && task.status !== "closed";
    });
    let pendingTask = taskData.filter((task) => {
      return task.category === "pending" && task.status !== "closed";
    });
    let historyTask = taskData.filter((task) => {
      return task.status === "closed"; //CHANGE THIS
    });
    this.setState({
      inboxTask: inboxTask,
      pendingTask: pendingTask,
      historyTask: historyTask,
      inboxSearchResults: inboxTask,
      pendingSearchResults: pendingTask,
      historySearchResults: historyTask,
    });
  };
}
// FILTER LISTS
const Filter1List = ["Alert", "Task", "Both"];
const Filter2List = ["Due Date", "Creation Date"];

const BlurEffectStyle = {
  display: "inline-flex",
  width: "100%",
  filter: "blur(10px)",
};

export { TaskManagement };
