import React, { useCallback } from "react";
import {
  fetchTaskAPIWithPermission,
  fetchAlertAPIWithPermission,
  fetchAdminAPIWithPermission,
} from "@aim-mf/api";
import "./app.scss";
import { TaskManagement } from "./page/taskManagement";
import { AuthCheckAndAutofix } from "@aim-mf/styleguide";

const initialState = {
  loadingUser: true,
  loadingTask: true,
  loadingAlert: true,
  alertList: [],
  userList: [],
  taskList: [],
  currentUser: {},
};

function App(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const {
    loadingUser,
    loadingTask,
    userList,
    taskList,
    currentUser,
    loadingAlert,
    alertList,
  } = state;

  React.useEffect(() => {
    //console.log(loadingTask)
    dispatch({
      type: "loadCurrentUserInfo",
      data: {
        ...JSON.parse(sessionStorage.getItem("CurrentUser")),
        ...JSON.parse(sessionStorage.getItem("MyProfile")),
      },
    });
    if (loadingUser) {
      dispatch({ type: "loadingUser" });
      const userSubscription = fetchAdminAPIWithPermission(
        "auth/profile/list"
      ).subscribe(
        (results) => {
          dispatch({ type: "loadUser", results });
        },
        (err) => {
          AuthCheckAndAutofix(err, () => {
            loadUserFunction();
          });
        }
      );

      return () => userSubscription.unsubscribe();
    }
    if (loadingTask) {
      dispatch({ type: "loadingTask" });
      const userSubscription = fetchTaskAPIWithPermission(
        "gettasks/"
      ).subscribe(
        (results) => {
          dispatch({ type: "loadTask", results });
        },
        (err) => {
          AuthCheckAndAutofix(err, () => {
            loadTaskFunction();
          });
        }
      );

      return () => userSubscription.unsubscribe();
    }
    if (loadingAlert) {
      dispatch({ type: "loadingAlert" });
      const userSubscription = fetchAlertAPIWithPermission(
        "getalertsbyrights/"
      ).subscribe(
        (results) => {
          dispatch({ type: "loadAlert", results });
        },
        (err) => {
          AuthCheckAndAutofix(err, () => {
            loadAlertFunction();
          });
        }
      );

      return () => userSubscription.unsubscribe();
    }
  }, [
    loadingUser,
    loadingTask,
    loadingAlert,
    loadUserFunction,
    loadTaskFunction,
    loadAlertFunction,
  ]);

  const loadUserFunction = useCallback(() => {
    const userSubscription = fetchAdminAPIWithPermission(
      "auth/profile/list"
    ).subscribe(
      (results) => {
        dispatch({ type: "loadUser", results });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          loadUserFunction();
        });
      }
    );

    return () => userSubscription.unsubscribe();
  });
  const loadTaskFunction = useCallback(() => {
    const userSubscription = fetchTaskAPIWithPermission("gettasks/").subscribe(
      (results) => {
        dispatch({ type: "loadTask", results });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          loadTaskFunction();
        });
      }
    );

    return () => userSubscription.unsubscribe();
  });
  const loadAlertFunction = useCallback(() => {
    const userSubscription = fetchAlertAPIWithPermission(
      "getalertsbyrights/"
    ).subscribe(
      (results) => {
        dispatch({ type: "loadAlert", results });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          loadAlertFunction();
        });
      }
    );

    return () => userSubscription.unsubscribe();
  });
  const handleCreateTask = React.useCallback((taskinfo) => {
    //console.log(taskinfo);
    const userSubscription = fetchTaskAPIWithPermission(
      "tasks/",
      taskinfo
    ).subscribe(
      (results) => {
        // once created, need to reload tasks
        //console.log(results);
        let taskID = results.data.id;
        if (taskinfo.data.attachments.length !== 0) {
          handleTaskAttachmentUpload(taskID, taskinfo.data.attachments);
        } else {
          dispatch({ type: "loadingTask" });
        }
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleCreateTask(taskinfo);
        });
      }
    );
    return () => userSubscription.unsubscribe();
  });

  const handleTaskAttachmentUpload = (taskID, files) => {
    const data = new FormData();
    files.forEach((file) => {
      data.append("files", file.attachment_file);
    });
    const TaskUploadSubscription = fetchTaskAPIWithPermission(
      "uploadattachment/task/" + taskID,
      { data: data }
    ).subscribe(
      (response) => {
        //console.log("upload finished");
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleTaskAttachmentUpload(taskID, files);
        });
      }
    );
    return () => TaskUploadSubscription.unsubscribe();
  };

  const handleActionAttachmentUpload = (action_id, files) => {
    const data = new FormData();
    files.forEach((file) => {
      data.append("files", file.attachment_file);
    });
    const taskActionFileUploadSubscription = fetchTaskAPIWithPermission(
      "uploadattachment/taskaction/" + action_id,
      { data: data }
    ).subscribe(
      (response) => {
        //console.log("upload finished");
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleActionAttachmentUpload(action_id, files);
        });
      }
    );
    return () => taskActionFileUploadSubscription.unsubscribe();
  };

  const handleTaskAttachmentDownload = React.useCallback(
    (attachmentID, attachmentFilename) => {
      const attachmentSubscription = fetchTaskAPIWithPermission(
        "task/attachment/" + attachmentID,
        { responseType: "arraybuffer" }
      ).subscribe(
        (response) => {
          let blob = new Blob([response.data], {
            type: extractContentType(response),
          });
          let downloadUrl = window.URL.createObjectURL(blob);
          let filename = attachmentFilename;
          //console.log(blob);

          let a = document.createElement("a");
          if (typeof a.download === "undefined") {
            window.location.href = downloadUrl;
          } else {
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
          }
        },
        (err) => {
          AuthCheckAndAutofix(err, () => {
            handleTaskAttachmentDownload(attachmentID, attachmentFilename);
          });
        }
      );
      return () => attachmentSubscription.unsubscribe();
    }
  );
  const handleTaskActionAttachmentDownload = React.useCallback(
    (attachmentID, attachmentFilename) => {
      const attachmentSubscription = fetchTaskAPIWithPermission(
        "taskaction/attachment/" + attachmentID,
        { responseType: "arraybuffer" }
      ).subscribe(
        (response) => {
          let blob = new Blob([response.data], {
            type: extractContentType(response),
          });
          let downloadUrl = window.URL.createObjectURL(blob);
          let filename = attachmentFilename;
          //console.log(blob);

          let a = document.createElement("a");
          if (typeof a.download === "undefined") {
            window.location.href = downloadUrl;
          } else {
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
          }
        },
        (err) => {
          AuthCheckAndAutofix(err, () => {
            handleTaskActionAttachmentDownload(
              attachmentID,
              attachmentFilename
            );
          });
        }
      );
      return () => attachmentSubscription.unsubscribe();
    }
  );

  const handleTaskAttachmentRemove = React.useCallback((attachmentIDList) => {
    const attachmentSubscription = fetchTaskAPIWithPermission(
      "removetaskattachments/",
      { data: attachmentIDList }
    ).subscribe(
      (response) => {
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleTaskAttachmentRemove(attachmentIDList);
        });
      }
    );
    return () => attachmentSubscription.unsubscribe();
  });
  const handleActionAttachmentRemove = React.useCallback((attachmentIDList) => {
    const attachmentSubscription = fetchTaskAPIWithPermission(
      "removeactionattachments/",
      { data: attachmentIDList }
    ).subscribe(
      (response) => {
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleActionAttachmentRemove(attachmentIDList);
        });
      }
    );
    return () => attachmentSubscription.unsubscribe();
  });
  const handleTaskUserSwitch = (task_id) => {
    //console.log(newTask);
    const userSubscription = fetchTaskAPIWithPermission(
      "switchtasktoandfrom/" + task_id
    ).subscribe(
      (results) => {
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleTaskUserSwitch(task_id);
        });
      }
    );

    return () => userSubscription.unsubscribe();
  };

  const handleTaskUpdate = (task_id, newTask) => {
    //console.log(newTask);
    const userSubscription = fetchTaskAPIWithPermission(
      "updatetasks/" + task_id,
      { data: newTask }
    ).subscribe(
      (results) => {
        if (
          newTask.task_new_attachments !== undefined &&
          newTask.task_new_attachments.length !== 0
        ) {
          handleTaskAttachmentUpload(task_id, newTask.task_new_attachments);
        }
        if (newTask.task_remove_attachment_ID.length !== 0) {
          handleTaskAttachmentRemove(newTask.task_remove_attachment_ID);
        }
        newTask.actions.forEach((action) => {
          if (action.action_type === "Upload Request") {
            if (
              action.action_new_attachment !== undefined &&
              action.action_new_attachment.length !== 0
            ) {
              handleActionAttachmentUpload(
                action.action_id,
                action.action_new_attachment
              );
            }
            if (action.action_remove_attachment_ID.length !== 0) {
              handleActionAttachmentRemove(action.action_remove_attachment_ID);
            }
          }
        });
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleTaskUpdate(task_id, newTask);
        });
      }
    );

    return () => userSubscription.unsubscribe();
  };

  const handleTaskClose = (task_id) => {
    const userSubscription = fetchTaskAPIWithPermission(
      "closetask/" + task_id
    ).subscribe(
      (results) => {
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleTaskClose(task_id);
        });
      }
    );

    return () => userSubscription.unsubscribe();
  };
  const handleTaskOpen = (task_id) => {
    const userSubscription = fetchTaskAPIWithPermission(
      "opentask/" + task_id
    ).subscribe(
      (results) => {
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleTaskOpen(task_id);
        });
      }
    );

    return () => userSubscription.unsubscribe();
  };
  const extractContentType = (response) => {
    //console.log(response);
    return response.headers["content-type"];
  };

  return (
    <div>
      <TaskManagement
        loading={loadingUser || loadingAlert || loadingTask}
        userList={userList}
        taskList={taskList}
        alertList={alertList}
        currentUser={currentUser}
        handleCreateTask={handleCreateTask}
        handleTaskUpdate={handleTaskUpdate}
        handleTaskClose={handleTaskClose}
        handleTaskOpen={handleTaskOpen}
        handleTaskUserSwitch={handleTaskUserSwitch}
        handleTaskAttachmentDownload={handleTaskAttachmentDownload}
        handleTaskActionAttachmentDownload={handleTaskActionAttachmentDownload}
        handleTaskAttachmentRemove={handleTaskAttachmentRemove}
        handleActionAttachmentRemove={handleActionAttachmentRemove}
      />
    </div>
  );

  function reducer(state = initialState, action) {
    switch (action.type) {
      case "loadCurrentUserInfo":
        return {
          ...state,
          currentUser: action.data,
        };
      case "loadingUser":
        // console.log("in loading riskList")
        return { ...state, loadingUser: true };
      case "loadingTask":
        //console.log("in loading task " + loadingTask);
        return { ...state, loadingTask: true };
      case "loadingAlert":
        // console.log("in loading riskList")
        return { ...state, loadingAlert: true };
      case "loadUser":
        return {
          ...state,
          userList: action.results.data,
          loadingUser: false,
        };
      case "loadTask":
        return {
          ...state,
          taskList: action.results.data,
          loadingTask: false,
        };
      case "loadAlert":
        //console.log("load alert");
        return {
          ...state,
          alertList: action.results.data,
          loadingAlert: false,
          loading: false,
        };
      default:
        throw Error(`Unknown action type '${action.type}'`);
    }
  }
}

export default App;
